import {
    PageContainerDPC,
    PrintButtonDPC,
    YtEmbedDPC
} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import moment from "moment";
import {prop, getCategoriaPrimariaEN} from "../functions/functions";

export default ({data, pageContext}) => {

    const breadcrumbItem = {
        name: data.node.field_titolo_esteso,
        slug: data.node.fields && data.node.fields.slug ? data.node.fields.slug : null,
        active: true
    }

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          pageId={data.node.drupal_internal__nid}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          //checkTorna={true}
                          //linkTorna={`/${pageContext.lang}${data.elenco?.fields?.slug}`}
                          //textTorna={data.elenco?.title}
                          elencoId={data.elenco?.drupal_internal__nid}
                          breadcrumbItem={breadcrumbItem}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >
            <section className="container article-card-simple sezione-dettaglio">
                <h1>Photos and Videos</h1>
                <div className="px-custom">
                    <article className="card-wrapper pb-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="category-top">
                                    <span className="data">{moment(data.node.field_data).format('D MMMM Y')}</span>
                                </div>
                                <h2 className="h5 card-title">{data.node.field_titolo_esteso}</h2>
                                <div className="card-text"
                                     dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                            </div>
                        </div>
                    </article>

                    <div className="gallery-photo-video-embed">
                        {data.node.field_video ?
                            <YtEmbedDPC
                                url={data.node.field_video.uri}
                                descrizione={data.node.field_video.title}/>
                            : null}
                    </div>

                    <PrintButtonDPC/>

                </div>
            </section>
        </PageContainerDPC>
    );
};


export const query = graphql`
query($slug: String!) {
    node: nodeVideo (fields: { slug: { eq: $slug } }){
      field_video {
        title
        uri
      }
      fields {
        slug
      }
      body {
        value
        processed
      }
      field_titolo_esteso
      title
      field_categoria_primaria
      drupal_internal__nid
      field_data
      relationships {
        field_sottodominio {
          name
        }
        field_immagine_dettaglio {
          field_alt
          field_didascalia
          relationships {
            image:field_immagine {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        field_riferimento_traduzione {
          fields {
            slug
          }
        }
      }
    },
    elenco:nodeElencoFotoEVideo (field_codice_lingua: {eq: true}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}) {
      title
      field_titolo_esteso
      drupal_internal__nid
      fields {
        slug
      }
    }
}
`;
